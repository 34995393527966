import React, { useState } from "react";
import { useEffect } from "react";
import { getMentors } from "../../queries/mentor";
import { Avatar, CircularProgress } from "@mui/material";
import Analysis from "./Analysis";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";
import { getWebcalls } from "../../queries/webcalls";
import { BASE_URL } from "../../queries/url";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Done } from "@material-ui/icons";

import axios from "axios";

const Index = () => {
    const [state, globalDispatch] = useGlobalContext();
    const [downloading, setDownloading] = useState("nope");
    

    useEffect(() => {
        if (!state.mentors) {
            globalDispatch({
                type: GActions.SET_MENTORS_LOADING,
                payload: true,
            });
            getMentors()
                .then((res) => {
                    globalDispatch({
                        type: GActions.SET_MENTORS,
                        payload: res,
                    });
                })
                .finally(() => {
                    globalDispatch({
                        type: GActions.SET_MENTORS_LOADING,
                        payload: false,
                    });
                });
        }


        if (!state.webcalls) {
            globalDispatch({
                type: GActions.SET_WEBCALLS_LOADING,
                payload: true,
            });

            getWebcalls()
                .then((res) => {
                    globalDispatch({
                        type: GActions.SET_WEBCALLS,
                        payload: res,
                    });
                })
                .finally(() => {
                    globalDispatch({
                        type: GActions.SET_WEBCALLS_LOADING,
                        payload: false,
                    });
                });
        }
    }, []);

    const onConvClick = (conv) => {
        // console.log(conv)
        globalDispatch({
            type: GActions.SET_SELECTED_CONVERSATION,
            payload: conv,
        });

        globalDispatch({
            type: GActions.SET_MAIL_RETELL,
            payload: null,
        });

        globalDispatch({
            type: GActions.SET_SELECTED_CONVERSATION_RETELL,
            payload: conv,
        });
        const mentor = state.mentors?.find((m) => m._id === conv.mentorId);
        globalDispatch({
            type: GActions.SET_SELECTED_MENTOR,
            payload: mentor,
        });
    };

    const [conversationCallId, setConversationCallId] = useState();

    useEffect(() => {
        const conversationDetail = async () => {
            try {
                const res = await axios.get(
                    `https://ok1qlahe4k.execute-api.ap-south-1.amazonaws.com/webcall/get`
                );
                let filteredConversations = res.data.filter(
                    (conversation) =>
                        conversation.callId !== null &&
                        conversation.callId !== undefined
                );
                if (state.user?.privilege !== "admin") {
                    filteredConversations = filteredConversations.filter(
                        (conversation) =>
                            (conversation?.program != "select" &&
                                conversation.university ===
                                    state.user?.privilege) || (state.user?.privilege === "GW Law" && conversation.university === "gwlaw" ) ||
                            (conversation.university === "USC" &&
                                state.user?.privilege ===
                                    conversation.university.toLowerCase())
                    );
                }
                await setConversationCallId(filteredConversations);
                return res;
            } catch (error) {
                console.log(error);
            }
        };
        conversationDetail();
    }, [setConversationCallId, state.user?.privilege]);
    function compareToCurrentDate(timestamp) {
        const date = new Date(timestamp);
        const today = new Date();

        const startOfToday = new Date(today.setHours(0, 0, 0, 0));
        const startOfYesterday = new Date(startOfToday);
        startOfYesterday.setDate(startOfYesterday.getDate() - 1);

        if (date.toDateString() === startOfToday.toDateString()) {
            return "Today";
        }

        if (date >= startOfYesterday && date < startOfToday) {
            return "Yesterday";
        }

        const startOfLastWeek = new Date(startOfToday);
        startOfLastWeek.setDate(startOfLastWeek.getDate() - 7);
        if (date >= startOfLastWeek && date < startOfYesterday) {
            return "Last Week";
        }

        return "Older";
    }

    const dateSet = [];

    return (
        <div className="grid grid-cols-6 h-screen bg-gray-50">
            <div className="col-span-2 overflow-auto max-h-screen">
                <div
                    className="ml-[0.15rem] mt-[3.15rem]"
                    style={{
                        fontFamily: "Nunito Sans, sans-serif",
                        fontSize: "2rem",
                        lineHeight: "1.5rem",
                        fontStyle: "normal",
                        fontWeight: 700,
                        color: "#18181B",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    {" "}
                    <h1 className="ml-4">Conversations</h1>
                    
                </div>
                <div className="flex flex-col gap-2 mt-8">
                    {/* {conversationCallId.map((webcall, i) => {
                        const returnComponent = (
                            <ConvCard
                                conv={webcall}
                                key={webcall._id}
                                selected={
                                    webcall.email ===
                                    state.conversations?.email
                                }
                                prevSelected={
                                    state.webcalls[i - 1]?.email ===
                                    state.conversations?.email
                                }
                                onClick={() => {
                                    onConvClick(webcall);
                                }}
                            />
                        );

                        const date = compareToCurrentDate(webcall.latestCallTs);

                        if (!dateSet.includes(date)) {
                            dateSet.push(date);
                            return (
                                <div key={i}>
                                    <div className="font-[Outfit] text-[#2d2d2d] text-[14px] ml-2 mb-2">
                                        {date}
                                    </div>
                                    {returnComponent}
                                </div>
                            );
                        }

                        return returnComponent;
                    })} */}

                    {conversationCallId !== undefined &&
                        conversationCallId
                            ?.slice()
                            .reverse()
                            .map((conv, i) => (
                                // <ConvCard
                                //     conv={conv}
                                //     callId={conv.callId}
                                //     key={conv._id}
                                //     onClick={() => onConvClick(conv)}
                                // />
                                conv.emailConversation && conv.emailConversation.length > 0 ? (
                                    <ConvCard
                                        conv={conv}
                                        callId={conv.callId}
                                        key={conv._id}
                                        onClick={() => onConvClick(conv)}
                                    />
                                ) : null
                            ))}
                </div>
            </div>
            <div className="col-span-4 px-6 py-4 h-full overflow-auto">
                <Analysis />
            </div>
        </div>
    );
};

const ConvCard = ({
    conv,
    selected = false,
    onClick,
    prevSelected = false,
    callId = "",
}) => {
    const [state] = useGlobalContext();

    const avatarStyle = selected
        ? {
              backgroundColor: "#4880FF",
              height: "50px",
              width: "50px",
          }
        : {
              height: "40px",
              width: "40px",
          };

    return (
        <div
            className={`pl-3 mr-1.5 ml-3 ${
                selected ? "py-5" : "py-3"
            } pr-1 cursor-pointer  relative hover:${
                selected ? "bg-white" : "bg-purple-50"
            } hover:shadow-[rgb(119_119_119)_0px_0px_4px] hover:rounded-md hover:bg-gray-200 transition-all ${
                selected
                    ? "bg-white border-r-8 my-2 border-[#4880FF] shadow-[rgba(0,_0,_0,_0.44)_0px_0px_4px] rounded-md"
                    : "border-b-[1px] border-gray-200 "
            } ${prevSelected ? "border-t-[1px] border-gray-200" : ""}}`}
            onClick={onClick}
        >
            <div className="flex gap-3">
                <Avatar style={avatarStyle}>
                    {conv?.name && typeof conv?.name === "string"
                        ? conv?.name[0] 
                        : ""}
                </Avatar>
                <div className="flex flex-col">
                    <span className="font-[Outfit] font-medium text-lg leading-4">
                        {conv?.name}
                    </span>
                    <span className="ml-1 font-[Outfit] text-sm mt-1 text-gray-500">
                        {new Date(conv?.createdAt).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false, // Use 24-hour format
                        })}
                        <br />
                        {selected && conv?.country && "From " + conv?.country}
                    </span>
                </div>
            </div>
        </div>
    );
};
export default Index;
