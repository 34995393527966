import React from "react";
import { Container, Header, Heading } from "../Overview/OverviewElements";
import { useHistory } from "react-router-dom";
import { BackButton } from "../Mentors/NewMentor";
import { Button } from "@mui/material";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";
import { useEffect } from "react";
import { getGroups } from "../../queries/groups";
import { register } from "../../queries/login";
import { useRef } from "react";
import { CSVLink } from "react-csv";

const NewUser = () => {
    const [submitting, setSubmitting] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [values, setValues] = React.useState({
        name: "",
        email: "",
        privilege: "",
        password: "",
    });
    const [state, dispatch] = useGlobalContext();
    const csvRef = useRef();

    useEffect(() => {
        if (!state.groups || state.groups.length === 0) {
            getGroups()
                .then((res) => {
                    dispatch({
                        type: GActions.SET_GROUPS,
                        payload: res.groups,
                    });
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const formValues = {
            ...values,
            universityName: state.groups.find(
                (g) => g.name === values.privilege
            ).university,
        };

        try {
            const result = await register(formValues);
            if (result.ok) {
                dispatch({
                    type: GActions.SET_SNACKBAR,
                    payload: {
                        message: "User created successfully",
                        severity: "success",
                    },
                });
                csvRef.current.link.click();
                setValues({
                    name: "",
                    email: "",
                    privilege: "",
                    password: "",
                });
            } else {
                dispatch({
                    type: GActions.SET_SNACKBAR,
                    payload: {
                        message:
                            result?.results[0]?.message ||
                            result.message ||
                            "Some error occured",
                        severity: "error",
                    },
                });
            }
        } catch (e) {
            console.log(e);
            dispatch({
                type: GActions.SET_SNACKBAR,
                payload: {
                    message: e.message,
                    severity: "error",
                },
            });
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) {
        return <div className="ml-2 mt-2 font-[Outfit]">Loading...</div>;
    }

    return (
        <Container>
            <Header>
                <Heading>
                    <BackButton />
                    New User
                </Heading>
            </Header>
            <CSVLink
                className="hidden"
                filename={values?.name || "new-user"}
                ref={csvRef}
                data={[values]}
            ></CSVLink>
            <form className="w-[700px]" onSubmit={handleSubmit}>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                        <label
                            htmlFor="name"
                            className="font-[Outfit] block text-lg font-medium leading-6 text-gray-900"
                        >
                            Name
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="name"
                                required
                                autoComplete="given-name"
                                className="block px-2 font-[Outfit] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6"
                                onChange={handleChange}
                                value={values.name}
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label
                            htmlFor="university"
                            className="font-[Outfit] block text-lg font-medium leading-6 text-gray-900"
                        >
                            Email
                        </label>
                        <div className="mt-2">
                            <input
                                name="email"
                                required
                                type="text"
                                autoComplete="email"
                                className="px-2 font-[Outfit] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6"
                                onChange={handleChange}
                                value={values.email}
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-6">
                        <label
                            htmlFor="password"
                            className="font-[Outfit] block text-lg font-medium leading-6 text-gray-900"
                        >
                            Password
                        </label>
                        <div className="mt-2">
                            <input
                                name="password"
                                required
                                type="text"
                                className="px-2 font-[Outfit] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6"
                                onChange={handleChange}
                                value={values.password}
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label
                            htmlFor="privilege"
                            className="font-[Outfit] block text-lg font-medium leading-6 text-gray-900"
                        >
                            Group
                        </label>
                        <div className="mt-2">
                            <select
                                name="privilege"
                                required
                                onChange={handleChange}
                                value={values.privilege}
                                className="px-2 font-[Outfit] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6"
                            >
                                <option value="" hidden>
                                    Choose a Group
                                </option>
                                {state.groups.map((g) => {
                                    return (
                                        <option key={g._id} value={g.name}>
                                            {g.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="sm:col-span-6 mt-8">
                    <Button
                        disabled={submitting}
                        type="submit"
                        variant="contained"
                    >
                        Create
                    </Button>
                </div>
            </form>
        </Container>
    );
};

export default NewUser;
