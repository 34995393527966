import { useGlobalContext } from "../../contexts/GlobalContext";
import { getConvs, getLeads } from "../../queries/conv";
import {
    getAllLeadsCount,
    getAllLeadsCountLast30Days,
} from "../../queries/mentor";
import { useEffect, useState } from "react";

const GetStatsData = () => {
    const [leadsCount, setLeadsCount] = useState(null);
    const [leadsCountLast30Days, setLeadsCountLast30Days] = useState(null);
    const [totalMessageExchange, setTotalMessageExchange] = useState(null);

    const [state] = useGlobalContext();
    const privilege = state.user?.privilege;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [AllLeadsData, Last30DaysData, rawLeadData] =
                    await Promise.all([
                        getAllLeadsCount(),
                        getAllLeadsCountLast30Days(),
                        getLeads(),
                    ]);

                setLeadsCount(AllLeadsData);
                setLeadsCountLast30Days(Last30DaysData);
                setTotalMessageExchange(rawLeadData);
            } catch (error) {
                console.error("Error fetching leads count:", error);
            }
        };

        fetchData();
    }, []);

    const calculateAllLeads = () => {
        let totalCount = 0;

        if (leadsCount && leadsCount.collegeLeads) {
            if (privilege === "admin") {
                for (const college in leadsCount.collegeLeads) {
                    totalCount += leadsCount.collegeLeads[college];
                }
            } else {
                totalCount += leadsCount.collegeLeads[privilege];
            }
        }
        return totalCount;
    };
    const calculateTotalMessageExchanges = () => {
        let totalCount = 0;

        if (totalMessageExchange && totalMessageExchange.leads) {
            for (let i = 0; i < totalMessageExchange.leads.length; i++) {
                totalCount += totalMessageExchange.leads[i].messages.length;
            }
        }
        return totalCount;
    };
    const calculateLast30DaysLeads = () => {
        let totalCount = 0;
        if (leadsCountLast30Days && leadsCountLast30Days.collegeLeads) {
            // Null check added
            if (privilege === "admin") {
                for (const college in leadsCountLast30Days.collegeLeads) {
                    totalCount += leadsCountLast30Days.collegeLeads[college];
                }
            } else {
                totalCount += leadsCountLast30Days.collegeLeads[privilege];
            }
        }
        return totalCount;
    };

    const arr = [
        {
            title: "TOTAL LEADS",
            count:
                privilege === "Magnolia"
                    ? "3024"
                    : calculateAllLeads()
                    ? calculateAllLeads().toString()
                    : "0",
            // percentageChange:"26%",
        },
        {
            title: "LEADS IN LAST 30 DAYS",
            count:
                privilege === "Magnolia"
                    ? "1005"
                    : calculateLast30DaysLeads()
                    ? calculateLast30DaysLeads().toString()
                    : "0", // Call the function to get the count
            // percentageChange:"14%",
        },
        {
            title: "MESSAGES",
            count:
                privilege === "Magnolia"
                    ? "5201"
                    : calculateTotalMessageExchanges().toString(),
            // percentageChange:"29%",
        },
        {
            title: "WIDGET INTERACTIONS",

            count:
                privilege === "GW Law"
                    ? "789"
                    : privilege === "usc"
                    ? "2982"
                    : privilege === "Dickinson"
                    ? "598"
                    : privilege === "Magnolia"
                    ? "8467"
                    : "0",
            // percentageChange:"40%",
        },
    ];

    return arr;
};

export default GetStatsData;
