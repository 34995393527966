import { InputBase, Paper } from "@mui/material";
import React from "react";
import { BsSearch } from "react-icons/bs";
import {
    FilterActions,
    useMentorFilters,
} from "../../contexts/MentorFilterContext";
import { useGlobalContext } from "../../contexts/GlobalContext";

const Search = ({ filterMentors = () => {} }) => {
    const [filter, dispatch] = useMentorFilters();
    const [state] = useGlobalContext();
    
    const onChangeSearch = (e) => {
        const value = e.target.value;
        let updatedFilter = {
            ...filter.mentorFilter,
            name: value,    
        }
        
        if(state.user?.privilege !== "admin") {
            updatedFilter = {
                ...filter.mentorFilter,
                name: value,
                university: state.user?.privilege,
            };
        }
        dispatch({
            type: FilterActions.SET_MENTOR_FILTER,
            payload: updatedFilter,
        });
        filterMentors(updatedFilter);
    };

    return (
        <Paper
            component={"form"}
            style={{
                margin: "2px 16px",
                padding: "3px 20px 3px 4px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                transition: "200ms ease-in-out",
            }}
            sx={{
                background: "#f2f2f2",
                "&:hover": {
                    background: "#dcdcdc",
                    boxShadow: "0 0 2px #555",
                },
                ":focus-within": {
                    background: "#ebebeb",
                    boxShadow: "0 0 2px #555",
                },
            }}
            elevation={0}
        >
            <BsSearch
                style={{
                    color: "#7a7a7a",
                    padding: "2px 6px",
                    fontSize: "30px",
                }}
            />
            <InputBase
                placeholder="Search"
                style={{
                    margin: "0",
                    marginLeft: "4px",
                    fontFamily: "Outfit",
                }}
                onChange={onChangeSearch}
            />
        </Paper>
    );
};

export default Search;
