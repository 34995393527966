import EmailDashboard from "../components/EmailDashboard";

const EmailDashboardPage = () => {
    return (
        <>
            <EmailDashboard />
        </>
    );
};

export default EmailDashboardPage;
