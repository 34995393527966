import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
    deleteMentor,
    deleteMentorById,
    getAllMentorsPerformance,
    getMentors,
    toggleMentorVisibility,
} from "../../queries/mentor";
import {
    Container,
    ExportButton,
    Header,
    Heading,
} from "../Overview/OverviewElements";
import Search from "./Search";
import UniSelect from "./UniSelect";
import { Box, Button, IconButton, Modal } from "@mui/material";
import { MdOutlineGridView, MdPersonAddAlt1 } from "react-icons/md";
import { FaList } from "react-icons/fa";
import MentorCard from "./MentorCard";
import styled from "@emotion/styled";
import MentorTable from "./MentorTable";
import {
    FilterActions,
    useMentorFilters,
} from "../../contexts/MentorFilterContext";

import { Link } from "react-router-dom";
import MentorInfo from "./MentorInfo";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";
import { CSVLink } from "react-csv";
import { getGroups } from "../../queries/groups";

const Index = () => {
    const [loading, setLoading] = useState(true);
    // const [mentors, setMentors] = useState([]);

    const [isCardView, setIsCardView] = useState(true);
    const [filter, dispatch] = useMentorFilters();

    const [state, globalDispatch] = useGlobalContext();

    const [showDetails, setShowDetails] = useState(false);
    const [mentorClicked, setMentorClicked] = useState({});

    const csvLinkRef = React.useRef();

    useEffect(() => {
        if (!state.mentors) {
            setLoading(true);
            getMentors()
                .then((data) => {
                    let sorted = data.sort((a, b) => {
                        if (a.name < b.name) return -1;
                        else if (a.name > b.name) return 1;
                        else return 0;
                    });
                    globalDispatch({
                        type: GActions.SET_MENTORS,
                        payload: sorted,
                    });
                    // setMentors(sorted);

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                })
                .finally(() => setLoading(false));
        }

        if (!state.groups || state.groups.length === 0) {
            setLoading(true);
            getGroups()
                .then((res) => {
                    globalDispatch({
                        type: GActions.SET_GROUPS,
                        payload: res.groups,
                    });
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        if (state.mentors) {
            let sorted = state.mentors.sort((a, b) => {
                if (a.name < b.name) return -1;
                else if (a.name > b.name) return 1;
                else return 0;
            });

            if (state.user?.privilege !== "admin") {
                sorted = sorted.filter(
                    (mentor) =>
                        mentor.university_mentor === state.user?.privilege
                );
            }
            dispatch({
                type: FilterActions.SET_MENTOR_FILTER_LIST,
                payload: sorted,
            });

            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (state.mentors) {
            let sorted = state.mentors.sort((a, b) => {
                if (a.name < b.name) return -1;
                else if (a.name > b.name) return 1;
                else return 0;
            });

            if (state.user?.privilege !== "admin") {
                sorted = sorted.filter(
                    (mentor) =>
                        mentor.university_mentor === state.user?.privilege
                );
            }
            dispatch({
                type: FilterActions.SET_MENTOR_FILTER_LIST,
                payload: sorted,
            });
        }
    }, [state.mentors, state.user?.privilege, dispatch]);

    const handleExportClick = () => {
        console.log("Exporting...");
        csvLinkRef.current.link.click();
    };

    const filterMentors = (options) => {
        let data = state.mentors;

        if (options.university !== "all") {
            data = data.filter(
                (mentor) => mentor.university_mentor === options.university
            );
        }

        data = data.filter((mentor) => {
            if (options.name === "") return true;
            else
                return mentor.name
                    .toLowerCase()
                    .includes(options.name.toLowerCase());
        });

        dispatch({ type: FilterActions.SET_MENTOR_FILTER_LIST, payload: data });
    };

    const toggleView = () => {
        setIsCardView(!isCardView);
    };

    const toggleHideMentor = async (
        mentor,
        setToggleState,
        setToggleSwitchLoading
    ) => {
        try {
            // console.log({ mentor, setToggleState, setToggleSwitchLoading });
            setToggleSwitchLoading(true);
            const res = await toggleMentorVisibility(mentor._id);
            console.log(res);
            globalDispatch({
                type: GActions.SET_SNACKBAR,
                payload: {
                    open: true,
                    message: res.message,
                    severity: res.ok ? "success" : "error",
                },
            });

            setToggleState(res.mentor?.test_mentor);
            setToggleSwitchLoading(false);

            const copyMentors = [...state.mentors];

            let updatedMentors = copyMentors.map((m) => {
                if (m._id === mentor._id)
                    return {
                        ...m,
                        test_mentor: res.mentor?.test_mentor,
                    };
                else return m;
            });

            // setMentors(updatedMentors);
            globalDispatch({
                type: GActions.SET_MENTORS,
                payload: updatedMentors,
            });
        } catch (err) {
            console.log(err);
        }
    };

    const onMentorClick = (mentor) => {
        setMentorClicked(mentor);
        setShowDetails(true);
    };

    const deleteMentor = async (mentor) => {
        try {
            const res = await deleteMentorById(mentor._id);
            globalDispatch({
                type: GActions.SET_SNACKBAR,
                payload: {
                    open: true,
                    message: res.message,
                    severity: res.ok ? "success" : "error",
                },
            });

            const copyMentors = [...state.mentors];

            let updatedMentors = copyMentors.filter(
                (m) => m._id !== mentor._id
            );

            // setMentors(updatedMentors);
            globalDispatch({
                type: GActions.SET_MENTORS,
                payload: updatedMentors,
            });

            setShowDetails(false);
            setMentorClicked({});
        } catch (err) {
            console.log(err.response);
        }
    };
    return (
        <Container>
            <Header>
                <Heading>Mentors</Heading>
                <div style={{ display: "flex" }}>
                    {!loading && (
                        <>
                            <Search filterMentors={filterMentors} />
                            <ExportButton onClick={handleExportClick}>
                                Export to Excel
                            </ExportButton>
                            <CSVLink
                                data={filter?.mentorFilterList}
                                filename={"mentors.csv"}
                                target="_blank"
                                ref={csvLinkRef}
                            />
                        </>
                    )}
                </div>
            </Header>
            <Header>
                {state.user?.privilege === "admin" && (
                    <UniSelect filterMentors={filterMentors} />
                )}
                <div className="ml-auto">
                    <Link to="/mentors/new">
                        <Button
                            style={{ color: "#4880FF" }}
                            endIcon={<MdPersonAddAlt1 />}
                        >
                            Add Mentor
                        </Button>
                    </Link>
                    <IconButton
                        style={{ marginLeft: "14px" }}
                        onClick={toggleView}
                    >
                        {isCardView ? (
                            <FaList style={{ fontSize: "18px" }} />
                        ) : (
                            <MdOutlineGridView style={{ fontSize: "18px" }} />
                        )}
                    </IconButton>
                </div>
            </Header>
            {isCardView ? (
                <CardContainer>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        filter.mentorFilterList.map((mentor) => (
                            <MentorCard
                                key={mentor._id}
                                mentor={mentor}
                                onClick={() => onMentorClick(mentor)}
                            />
                        ))
                    )}
                </CardContainer>
            ) : (
                <MentorTable
                    mentors={filter.mentorFilterList}
                    onClick={onMentorClick}
                    toggleMentor={toggleHideMentor}
                />
            )}
            {filter.mentorFilterList.length === 0 && !loading && (
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                    No mentors found
                </div>
            )}

            <Modal open={showDetails} onClose={() => setShowDetails(false)}>
                <Box className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 outline-none">
                    <MentorInfo
                        mentor={mentorClicked}
                        onClose={() => setShowDetails(false)}
                        toggleVisibility={toggleHideMentor}
                        deleteMentor={deleteMentor}
                    />
                </Box>
            </Modal>
        </Container>
    );
};

export const CardContainer = styled.div`
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-row-gap: 30px;
    grid-column-gap: 26px;
`;

export default Index;
