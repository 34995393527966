import axios from "axios";
import { BASE_URL } from "./url";

const loginAxios = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
});

export const getWebcalls = async () => {
    try {
        const result = await loginAxios.get(`/webcalls`);
        return result?.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};

export const getCallDataById = async (callId) => {
    try {
        const response = await loginAxios.get(`/webcalls/details/${callId}`);

        return response.data;
    } catch (e) {
        console.log(e);
        return { ok: false, ...e?.response?.data };
    }
};
