import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useGlobalContext } from "../../contexts/GlobalContext";

const SortDropdown = ({ leadsDataExcel, selected, setSelected }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState([
        "By ambassador",
        "Name",
        "Email",
        "Time",
        "Cycle",
    ]);

    const [state] = useGlobalContext();
    useEffect(() => {
        if (state.user?.privilege === "admin") {
            setOptions([
                "By ambassador",
                "Name",
                "Email",
                "Time",
                "Cycle",
                "By university",
            ]);
        }
    }, [state.user?.privilege]);
    const csvLink = useRef();

    const downloadCsvHandler = () => {
        if (csvLink.current && leadsDataExcel) {
            csvLink.current.link.click();
        } else {
            alert("Data is not ready yet");
        }
    };

    const setOption = (option) => {
        setSelected(option);
        setIsOpen(false); // Close the dropdown after selecting an option
    };

    const dropdownContainerStyle = {
        position: "relative",
        display: "inline-block",
        marginRight: "0.5rem",
    };

    const dropdownButtonStyle = {
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        border: "1px solid #D4D4D8",
        padding: "0.5rem 1rem",
        background: "#FFF",
        width: "230px",
    };

    const dropdownContentStyle = {
        display: isOpen ? "block" : "none",
        position: "absolute",
        top: "100%",
        backgroundColor: "#f1f1f1",
        minWidth: "160px",
        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
        borderRadius: "5px",
        zIndex: 1,
    };

    const optionStyle = {
        padding: "12px 16px",
        textDecoration: "none",
        display: "block",
        cursor: "pointer",
    };

    const exportButtonStyle = {
        color: "#FFF",
        textAlign: "center",
        fontFamily: "Nunito Sans, sans-serif",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
        borderRadius: "5px",
        background: "#4880FF",
        padding: "8px 16px",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        border: "1px solid #D4D4D8",
        width: "100%",
        height: "42px",
    };

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={dropdownContainerStyle}>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    style={dropdownButtonStyle}
                >
                    <img
                        src="/ambassadorSortIcon.svg"
                        alt="sort"
                        style={{
                            marginRight: "0.5rem",
                            verticalAlign: "middle", // Align the image vertically in the middle
                        }}
                    />
                    {"Sort: " + selected}
                </button>
                <div style={dropdownContentStyle}>
                    {options
                        .filter((option) => option !== selected)
                        .map((option) => (
                            <button
                                key={option}
                                onClick={() => setOption(option)}
                                style={optionStyle}
                            >
                                {option}
                            </button>
                        ))}
                </div>
            </div>
            <button
                onClick={downloadCsvHandler}
                type="submit"
                style={exportButtonStyle}
            >
                Export
            </button>
            <CSVLink
                data={leadsDataExcel || []}
                ref={csvLink}
                target="_blank"
                filename="leads.csv"
            />
        </div>
    );
};

export default SortDropdown;
