import React, { useEffect, useState } from "react";
import { Avatar, Chip, IconButton, Tooltip } from "@mui/material";
import { AiOutlineMore } from "react-icons/ai";
import moment from "moment";
import { BiCopy } from "react-icons/bi";
import AiSummary from "./AiSummary";
import { useGlobalContext } from "../../contexts/GlobalContext";

import axios from "axios";
import Transcript from "./Transcript";
import { get_program_label } from "./programData";
import TranscriptAccordion from "./TranscriptAccordion";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CallIcon from "@mui/icons-material/Call";

const Analysis = ({ onGenerateAiSummaryButtonClick }) => {
    const [state] = useGlobalContext();
    const [copyText, setCopyText] = React.useState("Copy");

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopyText("Copied!");
        setTimeout(() => {
            setCopyText("Copy");
        }, 1000);
    };

    if (!state.selectedConversationRetell) return <PlaceHolder />;
    const responseStudent =
        state.selectedConversationRetell.lastResponseStudent;
    const responseMentor = state.selectedConversationRetell.lastResponseMentor;
    const lastResponseDateStudent = new Date(responseStudent);
    const lastResponseDateMentor = new Date(responseMentor);
    let finalTime;
    if (
        lastResponseDateMentor !== undefined &&
        lastResponseDateStudent !== undefined
    ) {
        if (lastResponseDateMentor > lastResponseDateStudent)
            finalTime = lastResponseDateMentor;
        else finalTime = lastResponseDateStudent;
    } else if (
        lastResponseDateMentor === undefined &&
        lastResponseDateStudent !== undefined
    ) {
        finalTime = lastResponseDateStudent;
    } else if (
        lastResponseDateStudent === undefined &&
        lastResponseDateMentor !== undefined
    ) {
        finalTime = lastResponseDateMentor;
    } else {
        finalTime = "final";
    }

    return (
        <div className="w-full bg-white shadow-lg">
            <div className="w-full bg-[#4880FF] px-8 py-2 relative flex">
                <Avatar
                    style={{
                        height: "100px",
                        width: "100px",
                        fontSize: "65px",
                        transform: "translateY(50%)",
                        backgroundColor: "#4880FF",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.44)",
                    }}
                >
                    {state.selectedConversationRetell?.name[0]}
                </Avatar>
                <span className="font-[Outfit] text-4xl font-semibold mt-auto ml-6 text-white">
                    {state.selectedConversationRetell?.name}
                </span>
                <IconButton
                    style={{ position: "absolute", top: "2px", right: "4px" }}
                >
                    <AiOutlineMore style={{ color: "white" }} />
                </IconButton>
                <Chip
                    label={
                        "On " +
                        moment(
                            state.selectedConversationRetell?.updatedAt
                        ).format("Do MMM YYYY")
                    }
                    style={{
                        position: "absolute",
                        bottom: "9px",
                        right: "12px",
                        border: "1px solid white",
                        color: "white",
                    }}
                />
            </div>
            <div className="bg-white px-8">
                <div className="font-[Outfit] text-xl ml-28 mt-1 text-[#666]">
                    Initiated a conversation with Grace
                </div>
            </div>

            <div className="mx-8 mt-8 relative overflow-x-auto rounded-lg">
                <table className="w-full">
                    <tbody>
                        <tr className="odd:bg-[#fafafa] even:bg-[#f0f0f0]">
                            <td className="text-left px-4 py-1 font-['Inter']">
                                Name
                            </td>
                            <td className="flex-1 text-right font-semibold font-['Inter'] px-4 py-1">
                                {state?.selectedWebcall?.name}
                            </td>
                        </tr>
                        <tr className="odd:bg-[#fafafa] even:bg-[#f0f0f0]">
                            <td className="text-left px-4 py-1 font-['Inter']">
                                Email
                            </td>
                            <td className="group flex-1 text-right font-semibold px-4 py-1 font-['Inter']">
                                <Tooltip title={copyText}>
                                    <IconButton
                                        onClick={() =>
                                            copyToClipboard(
                                                state?.selectedWebcall?.email
                                            )
                                        }
                                    >
                                        <BiCopy style={{ fontSize: "16px" }} />
                                    </IconButton>
                                </Tooltip>
                                {state?.selectedWebcall?.email}
                            </td>
                        </tr>
                        <tr className="odd:bg-[#fafafa] even:bg-[#f0f0f0]">
                            <td className="text-left px-4 py-1 font-['Inter']">
                                Interested Programs
                            </td>
                            <td className="flex flex-wrap gap-y-1 justify-end text-right px-2 py-1">
                                {state?.selectedWebcall?.program?.map((p) => {
                                    return (
                                        <code className="font-['Inter'] text-sm ml-2 px-2 py-[2px] rounded-sm bg-[#a3d1ff]">
                                            {get_program_label(
                                                p,
                                                state?.user?.privilege
                                            )}
                                        </code>
                                    );
                                })}
                            </td>
                        </tr>
                        <tr className="odd:bg-[#fafafa] even:bg-[#f0f0f0]">
                            <td className="text-left px-4 py-1 font-['Inter']">
                                Country
                            </td>
                            <td className="flex-1 text-right font-semibold px-4 py-1 font-['Inter']">
                                {state?.selectedWebcall?.country ||
                                    "Not Specified"}
                            </td>
                        </tr>
                        <tr className="odd:bg-[#fafafa] even:bg-[#f0f0f0]">
                            <td className="text-left px-4 py-1 font-['Inter']">
                                Latest Call
                            </td>
                            <td className="flex-1 text-right font-semibold px-2 flex flex-wrap justify-end py-1 font-['Inter']">
                                <HighlightBox>
                                    <CalendarTodayIcon
                                        className="text-[#2D2D2D] mr-1"
                                        sx={{ fontSize: "18px" }}
                                    />
                                    {moment(
                                        state?.selectedWebcall?.latestCallTs
                                    ).format("Do MMM YYYY")}
                                </HighlightBox>

                                <HighlightBox>
                                    <AccessTimeIcon
                                        className="text-[#2D2D2D] mr-1"
                                        sx={{ fontSize: "18px" }}
                                    />
                                    {moment(
                                        state?.selectedWebcall?.latestCallTs
                                    ).format("hh:mm A")}
                                </HighlightBox>

                                {state?.selectedWebcall?.totalCalls > 1 && (
                                    <HighlightBox>
                                        <CallIcon
                                            className="text-[#2D2D2D] mr-1"
                                            sx={{ fontSize: "18px" }}
                                        />
                                        Total{" "}
                                        {state?.selectedWebcall?.totalCalls}{" "}
                                        calls
                                    </HighlightBox>
                                )}
                            </td>
                        </tr>
                        {state?.user?.privilege === "admin" && (
                            <tr className="odd:bg-[#fafafa] even:bg-[#f0f0f0]">
                                <td className="text-left px-4 py-1 font-['Inter']">
                                    University
                                </td>
                                <td className="flex-1 text-right font-semibold px-2 flex flex-wrap justify-end py-1 font-['Inter']">
                                    {state?.selectedWebcall?.university ||
                                        "Not Specified"}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="px-12 mt-8 pb-8 ">
                {/* <div className="font-[Outfit] font-semibold text-black text-xl">
          Call Transcript
        </div> */}
                {/* <p>{data}</p> */}
                {/* <AiSummary
          onGenerateAiSummaryButtonClick={onGenerateAiSummaryButtonClick}
        /> */}
                {state?.selectedWebcall?.webcalls?.map((call, i) => {
                    return (
                        <TranscriptAccordion
                            callNumber={
                                state?.selectedWebcall?.webcalls.length - i
                            }
                            callId={call.callId}
                            expanded={i === 0}
                            key={call.callId}
                            nthScheme={
                                state?.selectedWebcall?.webcalls.length > 1
                            }
                            program={call.program}
                            call_summary={call.call_summary}
                            transcript={call.transcript}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const HighlightBox = ({ children }) => {
    return (
        <span className="px-2 inline-flex items-center justify-center py-1 ml-1 text-[14px] text-[#2D2D2D] bg-gray-200 rounded-md">
            {children}
        </span>
    );
};

const DetailValue = ({ value, label, noCopy = false }) => {
    const [copyText, setCopyText] = React.useState("Copy");
    const [showCopy, setShowCopy] = React.useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopyText("Copied!");
        setTimeout(() => {
            setCopyText("Copy");
        }, 1000);
    };

    return (
        <div
            className="flex justify-between items-center -mt-[5px]"
            onMouseEnter={() => setShowCopy(true)}
            onMouseLeave={() => setShowCopy(false)}
        >
            <div className="font-[Outfit] text-md text-[#555]">{label}</div>
            <div className="font-[Outfit] text-md text-black flex items-center h-[32px]">
                {showCopy && !noCopy && (
                    <Tooltip title={copyText}>
                        <IconButton onClick={() => copyToClipboard(value)}>
                            <BiCopy style={{ fontSize: "16px" }} />
                        </IconButton>
                    </Tooltip>
                )}
                {value}
            </div>
        </div>
    );
};

const PlaceHolder = () => {
    return (
        <div className="flex flex-col items-center  h-screen">
            <img
                className="w-[41%] mt-24"
                src="/insight.svg"
                alt="Choose conversation"
            />
            <h3
                className=" text-3xl font-semibold text-gray-900 mt-6"
                style={{ fontFamily: "Nunito Sans, sans-serif" }}
            >
                Choose a conversation to get started
            </h3>
            <span
                className="text-lg text-gray-500"
                style={{ fontFamily: "Nunito Sans, sans-serif" }}
            >
                Get useful Insights and Summaries with The Power of AI
            </span>
        </div>
    );
};

export default Analysis;
