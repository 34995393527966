import WelcomeText from "./WelcomeText";
import DashboardElements from "./DashboardElements";
import StatsContainer from "./StatsContainer";
import MiddleContainer from "./MiddleContainer";
import BottomContainer from "./BottomContainer";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";
import { getLeads } from "../../queries/conv";
import {
    getAllMentorsPerformance,
    getMentorsByGroup,
} from "../../queries/mentor";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { BASE_URL } from "../../queries/url";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Done } from "@material-ui/icons";

const DashboardContainer = () => {
    const [state, globalDispatch] = useGlobalContext();

    const [leadsDataExcel, setLeadsDataExcel] = useState();
    const [downloading, setDownloading] = useState("nope");

    const user = state.user;
    const dpFetchMentors = async () => {
        if (!state.mentors) {
            globalDispatch({
                type: GActions.SET_MENTORS_LOADING,
                payload: true,
            });
            const result = await getMentorsByGroup();
            globalDispatch({
                type: GActions.SET_MENTORS,
                payload: result.mentors,
            });
            globalDispatch({
                type: GActions.SET_MENTORS_LOADING,
                payload: false,
            });
            if (!result.ok) {
                globalDispatch({
                    type: GActions.SET_SNACKBAR,
                    payload: {
                        open: true,
                        message: result.message,
                        severity: "error",
                    },
                });
            }
        }
    };

    const dpFetchConversations = async () => {
        if (!state.conversations) {
            globalDispatch({
                type: GActions.SET_CONVERSATION_LOADING,
                payload: true,
            });
            const resultTEST = await getAllMentorsPerformance(
                state.user?.privilege
            );
            const result = await getLeads();
            const leads = result.leads;

            if (state.user?.privilege === "boston") {
                const leadDataUniversity = leads.map((lead) => ({
                    // Name: lead.name,
                    "First Name": lead.firstname,
                    "Last Name": lead.lastname,
                    Email: lead.email,
                    Cycle: lead.year,
                    Program: lead.program,
                    Country: lead.country,
                    Mentor: lead.mentorName,
                    "Last Response Mentor": lead.lastResponseMentor,
                    "Last Response Student": lead.lastResponseStudent,
                    "Created On": new Date(lead.createdAt),
                }));
                setLeadsDataExcel(leadDataUniversity);
            } else {
                const leadDataUniversity = leads.map((lead) => ({
                    Name: lead.name,
                    Email: lead.email,
                    Cycle: lead.year,
                    Mentor: lead.mentorName,
                    "Last Response Mentor": lead.lastResponseMentor,
                    "Last Response Student": lead.lastResponseStudent,
                    "Created On": new Date(lead.createdAt),
                }));
                setLeadsDataExcel(leadDataUniversity);
            }
            globalDispatch({
                type: GActions.SET_ALL_MENTOR_PERFORMANCE,
                payload: resultTEST.mentorPerformances,
            });

            globalDispatch({
                type: GActions.SET_CONVERSATIONS,
                payload: leads,
            });
            globalDispatch({
                type: GActions.SET_CONVERSATION_LOADING,
                payload: false,
            });
            if (!result.ok) {
                globalDispatch({
                    type: GActions.SET_SNACKBAR,
                    payload: {
                        open: true,
                        message: result.message,
                        severity: "error",
                    },
                });
            }
        }
    };
    const fetchOverviewData = async () => {
        await dpFetchMentors();
        if (
            state.user?.privilege !== "Fisher" &&
            state.user?.privilege !== "PACE"
        ) {
            // await fetchGroups();
            await dpFetchConversations();
        }
    };

    useEffect(() => {
        fetchOverviewData();
    }, []);
    return (
        <>
            <DashboardElements.DasboardContainer>
                <div className="flex justify-between">
                    <WelcomeText />
                    <a
                        href={`${BASE_URL}/dashboard/summary`}
                        download
                        className="mr-4"
                        onClick={() => {
                            setDownloading("yep");
                            setTimeout(() => {
                                setDownloading("done");
                            }, 1500);
                        }}
                    >
                        <button className="bg-green-600 hover:bg-green-700 active:scale-95 text-white text-base font-[Inter] font-semibold px-3 py-1 rounded-md flex items-center justify-center gap-2">
                            Export All Leads{" "}
                            {downloading === "yep" && (
                                <CircularProgress size={18} color="inherit" />
                            )}
                            {downloading === "nope" && (
                                <FileDownloadIcon
                                    sx={{
                                        fontSize: "17px",
                                    }}
                                />
                            )}
                            {downloading === "done" && (
                                <Done
                                    sx={{
                                        fontSize: "17px",
                                    }}
                                />
                            )}
                        </button>
                    </a>
                </div>
                <StatsContainer />
                <MiddleContainer />
                <BottomContainer leadsDataExcel={leadsDataExcel} />
            </DashboardElements.DasboardContainer>
        </>
    );
};

export default DashboardContainer;
