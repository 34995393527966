function separateConversation(conversationText) {
    const rawMessages = conversationText.split(/(?=User:|Agent:)/);
    const separatedMessages = rawMessages
        .filter(msg => msg.trim()) // Remove empty messages
        .map(rawMessage => {
            if (rawMessage.startsWith("User:")) {
                return {
                    role: "user",
                    content: rawMessage.replace(/^User:\s*/, "").trim(),
                };
            } else if (rawMessage.startsWith("Agent:")) {
                return {
                    role: "agent",
                    content: rawMessage.replace(/^Agent:\s*/, "").trim(),
                };
            }
        });
    return separatedMessages;
}

export default separateConversation;
