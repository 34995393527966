import React, { useEffect, useState } from "react";
import { getCallDataById } from "../../queries/webcalls";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Tooltip,
} from "@mui/material";
import { useGlobalContext } from "../../contexts/GlobalContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { get_program_label } from "./programData";
import moment from "moment";
import { BiCopy } from "react-icons/bi";
import separateConversation from "../../utils/messageSeparator";

const TranscriptAccordion = ({
    callNumber,
    callId,
    program,
    expanded = false,
    nthScheme = false,
    call_summary,
    transcript,
}) => {
    const [state] = useGlobalContext();
    
    const [callData, setCallData] = useState(null);

    useEffect(() => {
        getCallDataById(callId, process.env.REACT_APP_BEARER_TOKEN).then(
            (data) => {
                setCallData(data);
            }
        );
    }, []);

    const suffixNum = (n) => {
        const strNum = n.toString();
        const lastDigit = strNum.slice(-1);
        let suffix;
        switch (lastDigit) {
            case "1":
                suffix = "st";
                break;
            case "2":
                suffix = "nd";
                break;
            case "3":
                suffix = "rd";
                break;
            default:
                suffix = "th";
        }

        return strNum + suffix;
    };

    const startMsg = nthScheme
        ? `${suffixNum(callNumber)} Call for`
        : "Call Details";

    const time = new Date(callData?.start_timestamp).toLocaleTimeString(
        "en-US",
        {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        }
    );

    const date = new Date(callData?.start_timestamp).toLocaleDateString(
        "en-US",
        {
            month: "short",
            day: "numeric",
            year: "numeric",
        }
    );

    function formatDuration(ms) {
        const duration = moment.duration(ms, "milliseconds");

        // If less than 60 seconds
        if (duration.asSeconds() < 60) {
            return `${duration.seconds()} s`;
        }

        // If more than 60 seconds
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        return `${minutes}m ${seconds}s`;
    }

    return (
        <Accordion
            defaultExpanded={expanded}
            elevation={0}
            className="border-[1px] border-gray-300 rounded-lg mt-3 last:border-b-[1px]"
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {!callData && (
                    <h2 className="font-[Inter] text-[#3D3D3D] text-[16px] font-bold">
                        Getting call details...
                    </h2>
                )}

                {callData && (
                    <div className="flex w-full items-center justify-start">
                        <span className="font-[Inter] text-[#3D3D3D] text-[16px] font-bold">
                            {startMsg}
                        </span>
                        {nthScheme && (
                            <span className="font-[Inter] text-[#3D3D3D] text-[16px] font-bold ml-1">
                                {get_program_label(
                                    program,
                                    state?.user?.privilege
                                )}
                            </span>
                        )}
                        <div className="ml-auto">
                            <HighlightBox>
                                Lasted {formatDuration(callData?.duration_ms)}
                            </HighlightBox>
                        </div>
                    </div>
                )}
            </AccordionSummary>
            <AccordionDetails>
                {callData && (
                    <CallDetails
                        callNumber={callNumber}
                        callId={callId}
                        program={program}
                        nthScheme={nthScheme}
                        callData={callData}
                        transcript={transcript}
                        call_summary={call_summary}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
};

const CallDetails = ({
    callNumber,
    callId,
    program,
    expanded = false,
    nthScheme = false,
    callData,
    transcript,
    call_summary,
}) => {
    const [state] = useGlobalContext();
    const [copyText, setCopyText] = React.useState("Copy");

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopyText("Copied!");
        setTimeout(() => {
            setCopyText("Copy");
        }, 1000);
    };

    return (
        <>
            <table className="w-full">
                <tbody>
                    <tr className="odd:bg-[#fafafa] even:bg-[#f0f0f0]">
                        <td className="text-left px-4 py-1 font-['Inter']">
                            Call ID
                        </td>
                        <td className="group flex-1 text-right font-semibold px-4 py-1 font-['Inter']">
                            <Tooltip title={copyText}>
                                <IconButton
                                    onClick={() =>
                                        copyToClipboard(
                                            state?.selectedWebcall?.email
                                        )
                                    }
                                >
                                    <BiCopy style={{ fontSize: "16px" }} />
                                </IconButton>
                            </Tooltip>
                            {callId}
                        </td>
                    </tr>
                    {nthScheme && (
                        <tr className="odd:bg-[#fafafa] even:bg-[#f0f0f0]">
                            <td className="text-left px-4 py-1 font-['Inter']">
                                Program Enquired
                            </td>
                            <td className="flex flex-wrap gap-y-1 justify-end text-right px-2 py-1">
                                <code className="font-['Inter'] text-sm ml-2 px-2 py-[2px] rounded-sm bg-[#a3d1ff]">
                                    {get_program_label(
                                        program,
                                        state?.user?.privilege
                                    )}
                                </code>
                            </td>
                        </tr>
                    )}

                    {nthScheme && (
                        <tr className="odd:bg-[#fafafa] even:bg-[#f0f0f0]">
                            <td className="text-left px-4 py-1 font-['Inter']">
                                Call Timestamp
                            </td>
                            <td className="flex-1 text-right font-semibold px-2 flex flex-wrap justify-end py-1 font-['Inter']">
                                <HighlightBox>
                                    <CalendarTodayIcon
                                        className="text-[#2D2D2D] mr-1"
                                        sx={{ fontSize: "18px" }}
                                    />
                                    {moment(callData?.start_timestamp).format(
                                        "Do MMM YYYY"
                                    )}
                                </HighlightBox>

                                <HighlightBox>
                                    <AccessTimeIcon
                                        className="text-[#2D2D2D] mr-1"
                                        sx={{ fontSize: "18px" }}
                                    />
                                    {moment(callData?.start_timestamp).format(
                                        "hh:mm A"
                                    )}
                                </HighlightBox>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <h3 className="mt-6 font-[Inter] text-[#3D3D3D] text-[16px] font-bold">
                Call Summary:{" "}
            </h3>
            <div className="font-['Inter'] text-[#3D3D3D] text-[15px] bg-[#fafafa] mt-1 py-2 px-3 rounded-lg">
                {call_summary}
            </div>

            <h3 className="mt-6 font-[Inter] text-[#3D3D3D] text-[16px] font-bold">
                Call Transcript:{" "}
            </h3>

            <div className="max-h-[300px] bg-[#fafafa] overflow-auto mt-1 py-2 px-3 rounded-lg">
                { transcript && transcript.length > 0 && separateConversation(transcript)?.map((message, index) => {
                    return (
                        <p className="mb-3" key={index}>
                            <span className="font-semibold">
                                {message.role}:{" "}
                            </span>{" "}
                            {message.content}
                        </p>
                    );
                })}
            </div>
        </>
    );
};

const HighlightBox = ({ children, bgColor = "#e5e7eb" }) => {
    return (
        <span
            className={`px-2 inline-flex font-[Inter] font-semibold items-center justify-center py-1 ml-1 text-[14px] text-[#2D2D2D] rounded-md`}
            style={{ backgroundColor: bgColor }}
        >
            {children}
        </span>
    );
};

export default TranscriptAccordion;
