import { useState } from "react";
import { useHistory } from "react-router-dom";
import { logoutQuery } from "../../queries/login";
import { LogoutIcon } from "./icons";
import sidebarData from "./newSidebarData";
import Sidebar from "./SidebarElements";
import { GActions, useGlobalContext } from "../../contexts/GlobalContext";

const adminOnly = ["/manage"];

const Index = () => {
    const [selected, setSelected] = useState(window.location.pathname);
    const history = useHistory();
    const [state, globalDispatch] = useGlobalContext();

    const handleLogoutClick = async () => {
        const result = await logoutQuery();

        localStorage.removeItem("user");
        globalDispatch({ type: GActions.SET_USER, payload: null });
        history.push("/login");
    };

    return (
        <Sidebar.Container>
            <Sidebar.Header>
                <img
                    src="/icons/NewLogo.png"
                    style={{ height: "2.5rem" }}
                    alt="logo"
                />
            </Sidebar.Header>
            <Sidebar.Navigation>
                {sidebarData.map((data) => {
                    if (
                        adminOnly.includes(data.to) &&
                        state.user?.privilege !== "admin"
                    ) {
                        return null;
                    }
                    if(state.user?.privilege === "boston" || state.user?.privilege === "GW Law" || state.user?.privilege === "usc") {
                        return <Sidebar.Item
                        {...data}
                        key={data.to}
                        selected={selected === data.to}
                        onClick={() => setSelected(data.to)}
                    />
                    }
                    return data.label != "Emails" &&  (
                        <Sidebar.Item
                            {...data}
                            key={data.to}
                            selected={selected === data.to}
                            onClick={() => setSelected(data.to)}
                        />
                    );
                })}
            </Sidebar.Navigation>

            <div
                style={{ position: "", bottom: "40px", width: "100%" }}
            >
                {/* <Sidebar.Separator /> */}
                <div
                    onClick={handleLogoutClick}
                    style={{
                        backgroundColor: "white" /* Green */,
                        border: "none",
                        color: "black",
                        textAlign: "center",
                        textDecoration: "none",
                        display: "inline-block",
                        fontSize: "16px",
                        cursor: "pointer",
                        borderRadius: "10px",
                        width: "100%",
                    }}
                >
                    <Sidebar.NavButton style={{ marginBottom: "40px" }}>
                        {" "}
                        <LogoutIcon style={{}} />
                        <Sidebar.ItemText>Logout</Sidebar.ItemText>
                    </Sidebar.NavButton>
                </div>
            </div>
            {/* <Sidebar.Item
        label="Logout"
        icon={<LogoutIcon />}
        button={true}
        onClick={handleLogoutClick}
        style={{
          marginTop: "auto",
        }}
      /> */}
            {/* </div> */}
        </Sidebar.Container>
    );
};

export default Index;
