import React, { useEffect } from "react";
import { Table } from "../Overview/OverviewElements";
import { Switch } from "@mui/material";

const MentorTable = ({ mentors, onClick, toggleMentor = () => {} }) => {
    return (
        <>
            <Table.Wrapper style={{ marginTop: "20px" }}>
                <thead>
                    <tr style={{ borderBottom: "1px solid #d2d2d2" }}>
                        <Table.Head></Table.Head>
                        <Table.Head>Name</Table.Head>
                        <Table.Head>Specialization</Table.Head>
                        <Table.Head>University</Table.Head>
                        <Table.Head>Hide</Table.Head>
                    </tr>
                </thead>
                <tbody>
                    {mentors.map((mentor) => {
                        return (
                            <RowComponent
                                mentor={mentor}
                                onClick={() => onClick(mentor)}
                                onSwitchClick={toggleMentor}
                            />
                        );
                    })}
                </tbody>
            </Table.Wrapper>
        </>
    );
};

const RowComponent = ({ mentor, onClick, onSwitchClick }) => {
    const [checked, setChecked] = React.useState(mentor?.test_mentor || false);
    const [loading, setLoading] = React.useState(false);
    const handleChange = () => {
        onSwitchClick(mentor, setChecked, setLoading);
    };

    useEffect(() => {
        setChecked(mentor?.test_mentor || false);
    }, [mentor]);

    return (
        <Table.Row key={mentor._id} onClick={onClick}>
            <Table.Data style={{ width: "8%" }}>
                <img
                    src={mentor?.img_url_web || "/avatar.webp"}
                    alt={mentor?.name + "'s img"}
                    style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                    }}
                />
            </Table.Data>
            <Table.Data style={{ width: "20%" }}>
                <b
                    style={{
                        fontFamily: "Outfit",
                        fontWeight: "400",
                    }}
                >
                    {mentor.name}
                </b>
            </Table.Data>
            <Table.Data style={{ width: "25%" }}>
                {mentor.specialization}
            </Table.Data>
            <Table.Data style={{ width: "30%" }}>
                {mentor.university}
            </Table.Data>
            <Table.Data style={{ width: "5%" }}>
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    onClick={(e) => e.stopPropagation()}
                    disabled={loading}
                />
            </Table.Data>
        </Table.Row>
    );
};

export default MentorTable;
